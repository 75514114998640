@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & select {
    padding: 10px 16px;

    @media (--viewportMedium) {
      padding: 4px 26px;
    }
  }
}

.bottomWrapper {
  composes: marketplaceModalBottomWrapper from global;

  margin: 20px 0;
}

.modalButton {
  composes: marketPlaceModalButton from global;
}
